.contact_section {
    padding: 30px 120px 60px 120px;
    background-color: #F4F4F4;
}
.contact_section .contact_content p {
    padding-bottom: 90px;
    color: #6f6e6e;
    font-family: kohinoorregular;
    margin: 0;
    font-size: 20px;
    line-height: 30px;
}
.contact_section .form_row {
    display: flex;
    justify-content: space-between;
}
.contact_section .form_row .contact_form {
    width: 40%;
}
.contact_section .form_row .contact_form form .parentSection {
    margin-bottom: 15px;
}
.contact_section .form_row .contact_form form label {
    color: #898989;
    font-family: kohinoorlight;
    font-size: 20px;
    margin-bottom: 5px;
}
.contact_section .form_row .contact_form form input {
    background: transparent;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding: 0 15px!important;
    border-radius: 0;
}
.contact_section .form_row .contact_form form input:focus {
    border: 1px solid #ced4da;
    box-shadow: none!important;
}
.contact_section .form_row .contact_form form select {
    background: transparent;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    appearance: auto;
    padding: 0 15px!important;
    border-radius: 0;
}
.contact_section .form_row .contact_form form select:focus {
    border: 1px solid #ced4da;
    box-shadow: none!important;
}
.contact_section .form_row .contact_form form textarea {
    border-radius: 0;
    height: 114px;
    background: transparent;
}
.contact_section .form_row .contact_form form textarea:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
}
.contact_section .form_row .contact_form form .send_btn img {
    cursor: pointer;
}
.contact_section .form_row .contact_form form .send_btn .submit_btn {
    border: none;
    line-height: 30px;
    background: #F36E21;
    font-size : 25px;
    font-family: kohinoorregular;
    width : 100%;
    color : white;
    margin-top: 15px;
    padding: 10px;
}
.contact_section .form_row .contact_form form .send_btn .submit_btn:focus {
    border: none;
    outline: none;
}
.contact_section .form_row .iframe_row {
    width: 45%;
}
.contact_section .form_row .iframe_row iframe {
    width: 100%;
    height: 360px;
    border: 0;
    margin: 0;
    padding: 0;
}
.iframe_row p {
    font-family: kohinoorregular;
    /* margin-top: 60px; */
    font-size: 18px;
    line-height: 30px;
    color: #6f6e6e;
    /* font-family: 'Kohinoorlight'; */
}
.error {
    font-size: 14px;
    color: #e70202;
    font-family: 'Kohinoorregular';
}
select {
    color: #898989!important;
}
option {
    color: #898989!important;
    background-color: #F4F4F4!important;
}
@media (max-width : 1199px) {
    .contact_section .contact_content p {
        font-size : 18px;
        line-height: 26px;
    }
    .contact_section {
        padding: 30px 90px 60px 90px;
    }
    .contact_section .contact_content p {
        padding-bottom : 60px;
    }
    .iframe_row p {
        margin-top: 20px;
        line-height: 26px;
    }
}
@media (max-width: 1024px) {
    .contact_section .contact_content p {
        font-size : 15px;
        line-height: unset;
    }
    .contact_section .form_row .contact_form form .send_btn .submit_btn {
        font-size : 20px;
        padding : 5px 0;
    }
    .contact_section {
        padding: 30px 45px 60px 45px !important;
   }
    .contact_section .form_row .contact_form {
        width: calc(100% - 50%);
        padding-right: 30px;
   }
    .contact_section .form_row .contact_form form label {
        font-size: 15px;
   }
    .contact_section .form_row .contact_form form input {
        border-radius: 0;
        box-shadow: none;
        color: #898989;
        height: 30px;
   }
    .contact_section .form_row .contact_form form select {
        border-radius: 0;
        box-shadow: none;
        color: #898989;
        height: 30px;
   }
    .contact_section .form_row .contact_form form textarea {
        height: 100px;
   }
    .contact_section .form_row .contact_form form .send_btn img {
        cursor: pointer;
   }
    .contact_section .form_row .iframe_row {
        padding-left: 30px;
        width: calc(100% - 50%);
   }
   .iframe_row p {
    line-height: unset;
}
}
@media (max-width: 892px) {
    .contact_section .form_row .iframe_row {
        padding-left: 30px;
        width: calc(100% - 50%);
   }
}
@media (max-width: 767px) {
    .contact_section {
        padding: 30px 30px 0px 30px !important;
   }
    .contact_section .contact_content p {
        padding-bottom: 30px;
   }
    .contact_section .form_row {
        flex-direction: column;
   }
    .contact_section .form_row .contact_form {
        padding-right: 0;
        width: 100%;
   }
    .contact_section .form_row .contact_form form label {
        font-size: 15px;
   }
    .contact_section .form_row .contact_form form input {
        border-radius: 0;
        box-shadow: none;
        color: #898989;
        height: 30px;
   }
    .contact_section .form_row .contact_form form select {
        border-radius: 0;
        box-shadow: none;
        color: #898989;
        height: 30px;
   }
    .contact_section .form_row .contact_form form textarea {
        height: 100px;
   }
    .contact_section .form_row .contact_form form .send_btn img {
        cursor: pointer;
   }
    .contact_section .form_row .iframe_row {
        padding-top: 60px;
        padding-left: 0;
        width: 100%;
   }
}
