.snippet {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    box-shadow: 0 .4rem .8rem -.1rem rgba(0, 32, 128, .1), 0 0 0 1px #f0f2f7;
    border-radius: .25rem;
    background: #00000087;
    opacity: .8;
}

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #fff;
    color: #fff;
    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
    0% {
        box-shadow: 9984px 0 0 0 #e21f26, 9999px 0 0 0 #f26f21, 10014px 0 0 0 #ffca05;
    }

    16.667% {
        box-shadow: 9984px -10px 0 0 #e21f26, 9999px 0 0 0 #f26f21, 10014px 0 0 0 #ffca05;
    }

    33.333% {
        box-shadow: 9984px 0 0 0 #e21f26, 9999px 0 0 0 #f26f21, 10014px 0 0 0 #ffca05;
    }

    50% {
        box-shadow: 9984px 0 0 0 #e21f26, 9999px -10px 0 0 #f26f21, 10014px 0 0 0 #ffca05;
    }

    66.667% {
        box-shadow: 9984px 0 0 0 #e21f26, 9999px 0 0 0 #f26f21, 10014px 0 0 0 #ffca05;
    }

    83.333% {
        box-shadow: 9984px 0 0 0 #e21f26, 9999px 0 0 0 #f26f21, 10014px -10px 0 0 #ffca05;
    }

    100% {
        box-shadow: 9984px 0 0 0 #e21f26, 9999px 0 0 0 #f26f21, 10014px 0 0 0 #ffca05;
    }
}