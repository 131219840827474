.slide,
#Map {
  width: 100%;
}

img[usemap] {
  border: none;
  height: auto;
  max-width: 100%;
  width: auto;
}


.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-indicators {
  display: none !important;
}

.space_slider_section {
  padding: 0 105px 60px 105px;
  background: #f4f4f4;
}

.slider-component-style {
  background-color: white;
  position: relative;
  /* margin-top: -25px;
  margin-bottom: -25px; */
}

.canvas-style {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.carousel-control-prev,
.carousel-control-next {
  width: 48px !important;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  bottom: auto !important;
  width: 3rem !important;
  height: 3rem !important;
  opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 30px;
  width: 100%;
  height: 100%;

  position: absolute;
}

.carousel-control-prev {
  left: -100px;

}

.carousel-control-next {
  right: -100px;
}

@media (max-width:1024px) {
  .carousel-control-prev {
    left: -40px;
  }

  .carousel-control-next {
    right: -40px;
  }

}

.crousel-image-col-desktop {
  max-width: 1000px;
  width: 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%236e6e6e%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236e6e6e'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


/* carousels.css */
.canvas-style {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  mix-blend-mode: color-burn;

}

.slider-component-style {
  position: relative;
  display: inline-block;
}

.slider-image {
  display: block;
}

.remove_top_margin {
  margin-top: 0px !important;
  padding-top: 0px !important;
}


.floor_heading {
  font-size: 30px !important;
  font-family: kohinoorsemibold !important;
  padding: 0 15px 60px 15px !important;
  margin: 0 !important;
}

@media (max-width: 1299px) {
  .floor_heading {
    font-size: 25px;
    padding-bottom: 30px !important;
  }
}


@media (max-width: 1024px) {
  .floor_heading {
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  .crousel-image-col-desktop {
    padding: 0 30px;
  }
}

@media (max-width: 525px) {
  .floor_heading {
    padding: 20px 0 30px 0;
  }
}