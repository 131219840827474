@font-face {
  font-family: "kohinoorbold";
  src: local("Kohinoor"),
    url(./fonts/KohinoorDevanagari-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Kohinoorsemibold";
  src: local("Kohinoor"),
    url(./fonts/KohinoorDevanagari-Semibold.otf) format("truetype");
}

@font-face {
  font-family: "Kohinoorlight";
  src: local("Kohinoor"),
    url(./fonts/KohinoorDevanagari-Light.otf) format("truetype");
}

@font-face {
  font-family: "Kohinoormedium";
  src: local("Kohinoor"),
    url(./fonts/KohinoorDevanagari-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Kohinoorregular";
  src: local("Kohinoor"),
    url(./fonts/KohinoorDevanagari-Regular.otf) format("truetype");
}

body {
  margin: 0;
}

.img_for_mobile {
  display: none;
}

.nav_link select {
  display: none;
}

/* new header css */
.buy_now_section {
  display: none;
}

.content_section_visitor ul {
  font-family: kohinoorlight;
  margin: 0;
  color: #6e6e6e;
  font-size: 15px;
  padding-left: 18px;
  height: 110px;
  overflow: hidden;
}
.visit_card_heading {
  font-family: kohinoorsemibold;
  font-size: 20px;
  min-height: 50px;
}

.header_section {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  background-color: #ffffff;
  padding: 23.5px 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_link option {
  color: #6e6e6e;
  background-color: #c3bfbf;
  box-shadow: none !important;
  font-size: 16px;
}

.searchLI {
  position: relative;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background-color: transparent;
}

.p-dialog .p-dialog-content:last-of-type {
  min-height: 50vh;
}

.p-dialog-draggable .p-dialog-header {
  border-radius: 0;
}

.p-dialog .p-dialog-content:last-of-type {
  border-radius: 0;
}

.searchList {
  background: #f4f4f4;
  margin-left: 15px;
  position: absolute;
  width: calc(100% - 15px);
  margin-top: 5px;
  padding-top: 10px;
  max-height: 230px;
  overflow: auto;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.searchList ul {
  list-style: none;
  padding-left: 15px;
}
.searchList ul li {
  padding-bottom: 10px;
  cursor: pointer;
}

.classForMargin {
  margin-top: 130px;
}

.header_section .head_logo .web_logo {
  cursor: pointer;
  /* width: 138px;
  height: 83px; */
  transition: 1s;
}

.header_section .head_logo .web_logo img {
  /* width: 129px;
  height : 83px; */
  width: 138px;
  height: 83px;
}

.header_section .head_nav_links {
  display: flex;
  align-items: center;
}

.header_section .head_nav_links .nav_links_div {
  font-size: 20px;
  line-height: 20px;
}

.header_section .head_nav_links .nav_links_div .language_dropdown {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: end;
  margin: 0;
  padding-bottom: 10px;
}

.header_section .head_nav_links .nav_links_div .language_dropdown .nav_link {
  padding: 0 15px;
  cursor: pointer;
}

.header_section .head_nav_links .nav_links_div .language_dropdown .nav_link i {
  font-size: 25px;
}

.header_section
  .head_nav_links
  .nav_links_div
  .language_dropdown
  .nav_link
  select {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 20px;
  color: #6e6e6e;
  appearance: auto !important;
  font-family: kohinoorregular;
  height: 100%;
  box-shadow: none !important;
}

.header_section
  .head_nav_links
  .nav_links_div
  .language_dropdown
  .nav_link
  select:focus {
  box-shadow: none;
}

.header_section .head_nav_links .nav_links_div .language_dropdown .nav_link1 {
  color: #6e6e6e;
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header_section .head_nav_links .nav_links_div .unOrderNavLinks {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin: 0;
  color: #6e6e6e;
  align-items: center;
}

.search_input_section input {
  height: 30px;
  border: none;
  background-color: #f4f4f4;
  margin-left: 15px;
  padding: 5px 40px 5px 15px;
  color: #6e6e6e;
  font-family: kohinoorregular;
  font-size: 18px;
  z-index: 99;
}
.search_input_section {
  position: relative;
}
.search_input_section input:focus {
  outline: none;
}
.for_search_bar {
  position: absolute;
  right: 15px;
  top: 5px;
  cursor: pointer;
}

.header_section .head_nav_links .nav_links_div .unOrderNavLinks .nav_link {
  padding: 0 15px;
  cursor: pointer;
  position: relative;
  font-family: kohinoorregular;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link
  .unOrderSubNavLinks {
  display: none;
  transition: 0.3s;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link
  .unOrderSubNavLinks
  .subsublink {
  display: none;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:hover
  .unOrderSubNavLinks {
  position: absolute;
  display: block;
  list-style: none;
  padding: 15px;
  justify-content: space-between;
  margin: 0;
  background: #f4f4f4;
  font-size: 18px;
  min-width: 255px;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:hover
  .unOrderSubNavLinks1 {
  position: absolute;
  display: flex;
  list-style: none;
  padding: 15px;
  justify-content: space-between;
  margin: 0;
  background: #f4f4f4;
  font-size: 18px;
  min-width: 500px;
  left: -161px;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link
  .unOrderSubNavLinks1 {
  display: none;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:hover
  .unOrderSubNavLinks
  li {
  padding-bottom: 10px;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:hover
  .unOrderSubNavLinks
  li
  div {
  padding: 5px;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:hover
  .unOrderSubNavLinks
  li:last-child {
  padding-bottom: 0;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:hover
  .unOrderSubNavLinks
  li
  .subsublink {
  display: none;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:hover
  .unOrderSubNavLinks
  li
  .subsublink {
  display: block;
  list-style: none;
  padding: 10px 0 0 15px;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:last-child {
  padding-right: 0;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:last-child:hover
  .unOrderSubNavLinks {
  right: 0;
}

.header_section
  .head_nav_links
  .nav_links_div
  .unOrderNavLinks
  .nav_link:nth-child(4):hover
  .unOrderSubNavLinks {
  right: 0;
}

.header_section .resp_head_nav_links {
  display: none;
}

.fa-angle-down {
  font-size: 12px;
}

.login_btn {
  padding-left: 15px;
}

.login_btn button {
  font-size: 20px;
  color: white;
  background: #f36e21;
  border: none;
  padding: 6px 6.7px;
  font-family: kohinoorregular;
}

.lib_login_btn {
  padding-left: 15px;
}

.lib_login_btn button {
  font-size: 20px;
  background: transparent;
  border: 1px solid #6e6e6e;
  padding: 5px 5.7px;
}

.categorySection {
  display: none;
}

.textContentMobile {
  display: none;
}
.imageMainHome {
  width: 70%;
}
.homeBottomText {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

@media (max-width: 1199px) {
  .header_section {
    padding: 13.89px 90px;
  }

  .classForMargin {
    margin-top: 94.75px;
  }

  .header_section .head_logo .web_logo {
    /* width: 215px;
  height: 45px; */
    width: 75%;
  }

  .header_section .head_logo .web_logo img {
    /* height: 62px;
      width : 97px; */
  }

  .header_section .head_nav_links .nav_links_div .language_dropdown {
    font-size: 18px;
  }

  .header_section
    .head_nav_links
    .nav_links_div
    .language_dropdown
    .nav_link
    i {
    font-size: 20px;
  }

  .header_section
    .head_nav_links
    .nav_links_div
    .language_dropdown
    .nav_link
    select {
    border: none;
    background: transparent;
    padding: 0;
    height: 100%;
  }

  .header_section
    .head_nav_links
    .nav_links_div
    .language_dropdown
    .nav_link
    select:focus {
    box-shadow: none;
  }

  .header_section .head_nav_links .nav_links_div .language_dropdown .nav_link1 {
    padding: 0 15px;
    cursor: pointer;
  }

  .header_section .head_nav_links .nav_links_div .unOrderNavLinks {
    font-size: 18px;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  .header_section .head_nav_links .nav_links_div .unOrderNavLinks .nav_link {
    padding: 0 15px;
    cursor: pointer;
  }

  .header_section
    .head_nav_links
    .nav_links_div
    .unOrderNavLinks
    .nav_link:last-child {
    padding-right: 0;
  }

  .lib_login_btn button {
    font-size: 18px;
    padding: 5px;
  }

  .login_btn button {
    font-size: 18px;
    padding: 6px;
  }

  .header_section
    .head_nav_links
    .nav_links_div
    .language_dropdown
    .nav_link
    select {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .homeBottomText {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .imageMainHome {
    width: 90%;
  }

  .static_section {
    /* padding: 4rem 0rem 18rem 6rem !important; */
  }
}
@media (max-width: 1024px) {
  .homeBottomText {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .header_section {
    padding: 13.89px 45px;
  }

  .static_section {
    /* padding: 4rem 0rem 24rem 6rem !important; */
  }

  .open-menu {
    position: absolute;
    background: white;
    /* box-shadow: 0 0.125rem 0.25rem rgba(255, 244, 244, 0.08); */
    right: 0;
    z-index: 99;
    width: 50%;
    text-align: left;
    padding: 30px 0;
    top: 90px;
  }

  .open-menu .responsive-menu {
    margin: 0;
    list-style: none;
    line-height: 30px;
    letter-spacing: 1px;
  }

  .open-menu .responsive-menu .nav_link {
    cursor: pointer;
  }

  .header_section .resp_head_nav_links .nav_link {
    cursor: pointer;
    display: block;
    align-items: center;
  }

  .header_section .nav_link i {
    font-size: 20px;
  }

  .header_section .nav_link select {
    margin: 0 15px !important;
    border: none;
    background: transparent;
    padding: 0;
    font-size: 15px;
    appearance: auto !important;
    color: #6f6e6e;
    font-family: kohinoorregular;
    height: 100%;
  }

  .header_section .nav_link select:focus {
    box-shadow: none;
  }

  .header_section .resp_head_nav_links .nav_icon button:focus {
    outline: 0;
    border: none;
    box-shadow: none;
  }

  .header_section .resp_head_nav_links .nav_icon .nav_link1 {
    color: #6f6e6e;
    padding: 0 15px !important;
  }

  .header_section .resp_head_nav_links .open-menu {
    position: absolute;
    background: whitesmoke;
    /* box-shadow: 0 0.125rem 0.25rem rgba(255, 244, 244, 0.08); */
    right: 0;
    z-index: 99;
    width: 50%;
    text-align: left;
    padding: 30px 0;
    top: 90px;
  }

  .header_section .resp_head_nav_links .open-menu .responsive-menu {
    padding-left: 30px;
    margin: 0;
    list-style: none;
    line-height: 30px;
    letter-spacing: 1px;
  }

  .header_section .resp_head_nav_links .open-menu .responsive-menu .nav_link {
    cursor: pointer;
    font-size: 15px;
  }

  .header_section .resp_head_nav_links .navbar-toggler {
    padding: 0 0 0 15px;
  }

  .main_content_details .heading_section .main_library_subHeading {
    font-size: 18px !important;
  }

  .firstSection_timing {
    font-size: 18px !important;
  }
}

@media (max-width: 992px) {
  .header_section .head_nav_links {
    display: none;
  }

  .classForMargin {
    margin-top: 93.75px;
  }

  .header_section .resp_head_nav_links {
    display: block;
  }

  .header_section {
    padding: 15.75px 45px;
  }
}

@media (max-width: 767px) {
  .open-menu {
    position: absolute;
    background: white;
    /* box-shadow: 0 0.125rem 0.25rem rgba(255, 244, 244, 0.08); */
    right: 0;
    z-index: 99;
    width: 50%;
    text-align: left;
    padding: 30px 0;
    top: 60px;
  }

  .open-menu .responsive-menu {
    margin: 0;
    list-style: none;
    line-height: 30px;
    letter-spacing: 1px;
  }

  .open-menu .responsive-menu .nav_link {
    cursor: pointer;
  }
}

@media (max-width: 575px) {
  .homeBottomText {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .open-menu {
    width: 100%;
    top: 88px;
    font-size: 18px;
  }

  .header_section {
    padding: 13.88px 30px;
  }

  .header_section .nav_link i {
    font-size: 15px;
    margin-left: 5px;
  }

  .header_section .resp_head_nav_links .open-menu {
    width: 100%;
    top: 60px;
  }

  .header_section .nav_link i {
    font-size: 15px;
    margin-left: 5px;
  }

  .lib_login_btn {
    padding-left: 0;
  }
}

@media (max-width: 525px) {
  .searchedList p {
    height: 45px;
  }
  .img_for_desktop {
    display: none;
  }
  .img_for_mobile {
    display: block;
  }

  .header_section {
    padding: 9.25px 15px;
  }

  .header_section .head_logo .web_logo {
    /* width: 125px;
  height: 25px; */
    width: 76.52% !important;
  }

  .header_section .head_logo .web_logo img {
    vertical-align: inherit;
    /* width: 64.5px;
      height: 41.5px; */
  }

  .header_section .resp_head_nav_links .nav_link i {
    font-size: 20px;
  }

  .header_section .resp_head_nav_links .nav_link select {
    margin: 0 7.5px 0 0px !important;
  }

  .header_section .resp_head_nav_links .nav_icon .nav_link1 {
    padding: 0 15px !important;
  }

  .header_section .resp_head_nav_links .nav_icon i {
    /* font-size: 30px !important; */
    color: #6e6e6e !important;
  }

  .header_section .resp_head_nav_links .navbar-toggler {
    padding: 0 0 0 7.5px;
  }

  .static_section {
    /* padding: 0 15px 15px 15px !important; */
  }

  .main_content_details {
    padding: 15px !important;
  }

  .main_heading {
    margin-bottom: 15px;
    font-size: 18px !important;
    font-family: kohinoorsemibold;
  }

  .categorySection {
    padding: 15px 0;
    background: #f4f4f4;
  }

  .categorySection .col-3 {
    padding: 0 15px;
  }

  .categorySection .col-3 img {
    width: 100%;
  }

  .category_name {
    color: #6e6e6e;
    font-family: kohinoorregular;
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 5px;
    text-align: center;
    /* font-family: kohinoorregular;
    font-size: 14px;
    color: #6E6E6E;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center; */
  }

  .buy_now_section {
    position: fixed;
    display: block;
    bottom: 0;
    width: 100%;
    color: white;
    padding: 6px;
    font-size: 18px;
    font-family: "Kohinoorregular";
    background: #f56923;
  }

  .buy_now_section .col-6 {
    padding: 8.5px;
    text-align: center;
  }

  .buy_now_section .col-6:first-child {
    border-right: 1px solid white;
  }

  .buy_now_section .col-6 img {
    margin-right: 15px;
  }

  .margin_section {
    padding: 24px 0;
  }
  .content_section_visitor ul {
    height: auto;
  }
}

@media (max-width: 350px) {
  /* .header_section {
      padding: 18px 30px;
  } */
  /* .header_section .head_logo .web_logo {
      width: 50%;
  } */
  .header_section .head_logo .web_logo img {
    vertical-align: inherit;
  }

  .header_section .resp_head_nav_links .nav_link i {
    font-size: 15px;
  }

  .header_section .resp_head_nav_links .nav_link select {
    margin: 0 7.5px 0 0px !important;
  }

  .header_section .resp_head_nav_links .nav_icon .nav_link1 {
    padding: 0 7.5px !important;
  }

  .header_section .resp_head_nav_links .nav_icon i {
    /* font-size: 15px !important; */
  }
}

#close-icon1 {
  background: #efefef;
  /* box-shadow: 0 0.125rem 0.25rem #fff4f414; */
  width: 100%;
  z-index: 99;
  position: absolute;
  top: 60px;
  text-align: left;
  padding: 30px 0;
  left: 0;
}

#close-icon1 ul {
  /* padding: 0!important; */
  list-style: none;
}

.responsive-menu {
  padding-left: 30px;
  margin: 0;
  color: #6f6e6e;
  font-family: "Kohinoorregular";
}

#dropdownMenuLink {
  font-size: 20px;
  color: white !important;
  background: #f36e21;
  border: none;
  border-radius: 0;
  padding: 1px 20px;
  font-family: kohinoorregular;
}

#dropdownMenuLink:focus {
  box-shadow: none !important;
}

.language_dropdown .login_btn {
  position: relative;
}

.dropdown {
  position: unset !important;
}

.dropdown-menu.show {
  border: none;
  background: #f1f1f1;
  padding: 15px;
  border-radius: 0;
  transform: translate3d(0px, 32px, 0px) !important;
  right: 0px !important;
  left: unset !important;
}
.visit_listing_section .nav_link_cards .project_card {
  margin-bottom: 30px;
}

.dropdown-menu.show a {
  padding: 0px 0 15px 0;
  font-size: 18px;
  color: #6e6e6e !important;
  font-family: kohinoorregular;
}

.dropdown-menu.show button {
  font-size: 18px !important;
}

/* .dropdown-menu.show a:last-child {
  padding-bottom : 0;
} */
.dropdown-menu.show a:focus {
  background-color: transparent;
}

.dropdown-menu.show a:hover {
  background-color: transparent;
}

.dropdown-menu li {
  /* position: relative; */
}

.dropdown-menu .dropdown-submenu {
  display: none;
  border: none;
  position: relative;
  padding: 15px;
  border-radius: 0;
  width: 100%;
  margin-bottom: 15px;
  transition: 0.2s;
  background: #f1f1f1;
  padding: 0 0 0 30px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  transition: 0.2s;
}

.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
  transition: 0.2s;
}

/* @media (max-width : 1199px) {
      .dropdown-menu.show {
          right : -101px!important;
      }
  }  */
#resp_auth {
  border: none;
  background: transparent;
  padding: 0;
}

/* home page css */

.homePageMap {
  padding-top: 90px !important;
  display: flex;
  justify-content: center;
  width: 100%;
}

.static_section {
  background: #f4f4f4;
  /* padding: 5rem 7rem 30rem 6rem; */
}

.static_section .map_col img {
  width: 100%;
  /* height: 100%; */
}
.map_col video {
  width: 100%;
}

.main_content_details {
  padding: 0px 120px;
  background-color: #f4f4f4;
}

.details_section {
  margin-bottom: 30px;
}

.details_section:last-child {
  margin-bottom: 0;
}

.main_content_details .heading_section .main_heading_desktop {
  font-size: 30px;
  font-family: kohinoorsemibold;
  padding-bottom: 15px;
  margin: 0;
}

.main_content_details .heading_section .main_library_subHeading {
  font-size: 25px;
  font-family: kohinoorregular;
  /* padding-bottom: 15px; */
  color: #6e6e6e;
  margin: 0;
}

.main_content_details .heading_section .firstSection_timing {
  font-size: 25px;
  font-family: kohinoorregular;
  padding-bottom: 15px;
  color: #6e6e6e;
  margin: 0;
}

.main_content_details .heading_section .main_heading_mobile {
  display: none;
}

.main_content_details .both_section .content_section p {
  padding-bottom: 15px !important;
  font-family: kohinoorlight;
  margin: 0;
  color: #6e6e6e;
  font-size: 20px;
}

.main_content_details .both_section .content_section p:last-child {
  padding-bottom: 0 !important;
}

.main_content_details .both_section .images_section {
  width: calc(100% / 0.66);
  height: 100%;
  padding-left: 30px;
}

.main_content_details .both_section .images_section img {
  width: 100%;
  height: 345px;
  object-fit: cover;
}

@media (max-width: 1299px) {
  .main_content_details .heading_section .main_heading_desktop {
    font-size: 25px;
  }

  .main_content_details .both_section .images_section img {
    height: 300px;
  }
}

@media (max-width: 1199px) {
  .main_content_details {
    padding: 60px 90px;
  }

  .static_section {
    /* padding: 0 90px 90px 90px; */
  }
}

@media (max-width: 1024px) {
  .homePageMap {
    padding-top: 60px !important;
  }

  .main_content_details {
    padding: 45px;
  }

  .main_content_details .heading_section .main_heading_desktop {
    font-size: 25px;
    padding-bottom: 15px;
  }

  .main_content_details .both_section .images_section {
    padding-left: 15px;
  }

  .main_content_details .both_section .images_section img {
    height: 225px;
  }

  .details_section {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .homePageMap {
    padding-top: 60px !important;
  }
  .main_content_details {
    padding: 30px 30px 30px 30px;
  }

  .main_content_details .heading_section .main_heading_desktop {
    font-size: 18px;
    padding-bottom: 15px;
  }

  .main_content_details .both_section .images_section img {
    height: 185px;
  }
}

@media (max-width: 525px) {
  .homePageMap {
    padding-top: 30px !important;
    padding-bottom: 15px !important;
  }
  .main_content_details .both_section {
    flex-direction: column-reverse;
  }

  .search_input_section input {
    margin-left: 0;
  }

  .search_input_section input {
    width: 100%;
  }

  .for_search_bar {
    top: 7px;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    justify-content: end;
  }

  .searchList {
    width: 100%;
    margin-left: 0;
  }

  .searchList ul {
    margin-bottom: 0;
  }

  .classForMargin {
    margin-top: 107px;
  }

  .main_content_details .both_section .images_section {
    padding-left: 0;
    padding-bottom: 15px;
    width: 100%;
  }

  .main_content_details .both_section .images_section img {
    height: 185px;
  }

  .main_content_details .both_section .content_section {
    padding-right: 0;
    width: 100%;
  }

  /* .accessibility_section {
    padding : 30px
  } */

  .main_content_details .both_section .content_section p:last-child {
    padding-bottom: 0;
  }

  .main_content_details .both_section .content_section p {
    font-size: 14px;
  }

  .details_section {
    margin-bottom: 30px;
  }

  .main_heading_mobile {
    display: block !important;
    font-size: 18px;
    font-family: kohinoorsemibold;
    color: #1e1e1e;
    margin-bottom: 15px;
  }

  .main_heading_desktop {
    display: none !important;
  }

  .main_library_subHeading {
    display: none !important;
  }

  .firstSection_timing {
    display: none !important;
  }

  .categorySection {
    display: block;
  }

  .textContentMobile {
    display: block;
  }
}

.category_section {
  padding: 90px 60px;
  background: #f4f4f4;
}

/* breadcrumb css */
.breadcrumb_section {
  padding: 18.5px 120px;
  background: #ffffff;
}

.breadcrumb_section div {
  font-size: 19px;
  font-family: "Kohinoorregular";
}

.breadcrumb_section .breadcrumb_links {
  display: flex;
  align-items: baseline;
  color: #6e6e6e;
}

.breadcrumb_section .breadcrumb_links .breadcrumb_dots {
  width: 5px;
  height: 5px;
  border-radius: 25px;
}

.breadcrumb_section .breadcrumb_links .breadcrumb_dots1 {
  width: 5px;
  height: 5px;
  border-radius: 25px;
  margin: 0 3px 0 3px;
  background: #e21f26;
}

.breadcrumb_section .breadcrumb_links .breadcrumb_dots2 {
  width: 5px;
  height: 5px;
  border-radius: 25px;
  margin: 0 3px 0 0;
  background: #f26f21;
}

.breadcrumb_section .breadcrumb_links .breadcrumb_dots3 {
  width: 5px;
  height: 5px;
  border-radius: 25px;
  margin: 0 8px 0 0;
  background: #ffca05;
}

/* .child-menu {
  display: none;
}

.subpage:hover>.child-menu {
  display: block;
} */

div.unOrderSubNavSpacesLinks {
  display: flex;
  width: 550px;
  justify-content: space-between;
  list-style: none;
  /* padding: 15px; */
  margin: 0;
  background: #f4f4f4;
  font-size: 18px;
}
.unOrderSubNavSpacesLinks .nav_link {
  color: #212529;
}
.space {
  position: absolute;
  left: -200px;
  list-style: none;
  padding: 15px;
  margin: 0;
  background: #f4f4f4;
  font-size: 18px;
  display: none;
  transition: 0.3s;
}

.nav_link:hover .space {
  display: block;
  /* position: absolute;
  list-style: none;
  padding: 15px;
  justify-content: space-between;
  margin: 0;
  background: #f4f4f4;
  font-size: 18px; */
}
.child-menu li {
  list-style: none;
  padding-bottom: 10px;
}
.subpage .for_space {
  padding-bottom: 10px;
  /* color: #212529; */
}
.subpage .child-menu:last-child li {
  padding-bottom: 0;
}
.spaces_class {
  padding-left: 15px;
  color: #212529;
}
.unOrderSubNavLinks .subpage:last-child .for_space {
  padding-bottom: 0;
}

.dropdownBtn {
  font-size: 20px;
  border: 1px solid #c1c1c1 !important;
  padding: 6px 6.7px;
  font-family: kohinoorregular;
  width: 100%;
  color: var(--bs-body-color) !important;
}

.astronomyBtnList .login_btn {
  padding-left: 0;
  padding-right: 15px;
  white-space: nowrap;
  margin-bottom: 10px;
}

.arrowDown {
  flex: 0 0 auto;
}

@media (min-width: 769px) {
  .astronomyBtnDropDown {
    display: none;
  }

  .astronomyBtnList {
    flex-wrap: wrap;
    display: flex;
  }
}

/* Hide buttons on mobile screens */
@media (max-width: 768px) {
  .astronomyBtnDropDown {
    display: block;
    margin-bottom: 15px;
  }

  .astronomyBtnList {
    display: none;
  }
}

@media (max-width: 768px) {
  .py-custom-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.detailCardUpper span {
  width: 30px !important;
}
