.library_section_desktop {
  padding: 90px 120px;
}

.accessibility_footer_section {
  padding: 0px 120px 60px 120px;
  background-color: #f4f4f4;
}

.accessibility_footer_section p {
  padding-bottom: 15px !important;
  font-family: kohinoorlight;
  margin: 0;
  color: #6e6e6e;
  font-size: 20px;
}

.lib-content-col {
  padding: 0px 15px 0px 0px !important;
}

.lib-image-col-desktop {
  padding: 0px 0px 0px 15px !important;
}

.lib-image-col-mobile {
  display: none;
}

.search_card_section {
  padding: 0 120px 90px 120px;
  background: #f4f4f4;
}

.search_cat_section {
  padding: 0px 15px 0px 0px !important;
}

.combined_content {
  padding: 30px;
  background: white;
  min-height: 231px;
}

.search_cat_btn {
  background: #f56923;
  color: white;
  border: none;
  padding: 10px 0;
  width: 100%;
  font-family: kohinoorregular;
  font-size: 30px;
  display: none;
}

.for_another_page {
  background: white !important;
}

.search_cat_section_content {
  font-family: kohinoorregular;
  margin: 0;
  color: #6e6e6e;
  font-size: 20px;
}

/* .search_cat_section_content span:hover {
} */
.tooltip-span:hover {
  color: rgb(0, 0, 255);
  text-decoration: underline;
}

.book_section {
  padding: 0px 0px 0px 15px !important;
}

.search_cat_section_heading {
  font-size: 30px;
  font-family: kohinoorsemibold;
  padding-bottom: 15px;
  margin: 0;
}

.overview_section .static_row .map_col img {
  width: 100%;
}

.redirection_card_section .main_heading {
  font-size: 30px;
  font-family: kohinoorsemibold;
  padding: 0 15px 60px 15px;
  margin: 0;
}

.redirection_card_section {
  padding: 0 105px 90px 105px;
  background: #f4f4f4;
}

.nav_link_cards {
  /* justify-content: space-between; */
  display: flex;
}

.nav_link_cards .project_card {
  width: calc(100% / 3);
  padding: 0 15px;
}

.nav_link_cards .project_card .card_box {
  width: 100%;
}

.nav_link_cards .project_card .card_box .card_image img {
  width: 100%;
  /* height: 100%; */
}

.nav_link_cards .project_card .card_box .card_content {
  background: white;
  padding: 30px;
  position: relative;
  /* min-height: 365px; */
}

.nav_link_cards .project_card .card_box .card_content .card_heading {
  font-size: 25px;
  /* font-weight: 700; */
  /* padding-bottom: 30px; */
  font-family: kohinoorsemibold;
}

.nav_link_cards .project_card .card_box .card_content .design_section {
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 100%;
  background-color: #ffca05;
}

.infoSection {
  display: none;
}

.footer_mobile {
  display: none;
}

.tooltip_url {
  font-family: kohinoorregular;
  margin: 0;
  color: #6e6e6e;
  font-size: 20px;
  font-weight: 400 !important;
  background-color: #f4f4f4;
  padding: 10px;
}

.visitor_card_heading {
  font-size: 20px;
  font-family: kohinoorsemibold;
  height: 75px;
  overflow: hidden;
}

.responsive-image {
  width: 100%;
}

@media (max-width: 1299px) {
  .breadcrumb_section div {
    /* font-size: 23px; */
    font-family: "Kohinoorregular";
  }

  .breadcrumb_section div span {
    font-size: 35px;
  }

  .breadcrumb_section .breadcrumb_dots {
    width: 4px;
    height: 4px;
  }

  .breadcrumb_section .breadcrumb_dots:first-child {
    margin: 0 3px 0 3px;
    background: #ffca05;
  }

  .breadcrumb_section .breadcrumb_dots:nth-child(2) {
    margin: 0 3px 0 0;
    background: #f26f21;
  }

  .breadcrumb_section .breadcrumb_dots:last-child {
    margin: 0 8px 0 0;
    background: #e21f26;
  }

  .search_cat_section_heading {
    font-size: 25px;
  }

  .search_cat_btn {
    font-size: 25px;
  }

  .redirection_card_section .main_heading {
    font-size: 25px;
    padding-bottom: 30px;
  }

  .nav_link_cards .project_card .card_box .card_content .card_heading {
    font-size: 20px;
  }

  .combined_content {
    min-height: 225px;
  }
}

@media (max-width: 1199px) {
  .breadcrumb_section {
    padding: 18.5px 90px;
  }

  .library_section_desktop {
    padding: 60px 90px;
  }

  .accessibility_footer_section {
    padding: 0px 90px 60px 90px;
  }

  .search_card_section {
    padding: 0 90px 60px 90px;
  }

  /* .redirection_card_section [
        padding: 0 75px ;
    ] */
  .redirection_card_section {
    padding: 0 75px 90px 75px;
  }
}

@media (max-width: 1024px) {
  .breadcrumb_section {
    padding: 18.5px 45px;
    background: #ffffff;
  }

  .breadcrumb_section div {
    font-size: 18px;
    font-family: "Kohinoorregular";
  }

  .breadcrumb_section div span {
    font-size: 35px;
  }

  .breadcrumb_section .breadcrumb_dots {
    width: 4px !important;
    height: 4px !important;
  }

  .library_section_desktop {
    padding: 60px 45px;
  }

  .accessibility_footer_section {
    padding: 0px 45px 60px 45px;
  }

  .search_card_section {
    padding: 0 45px 60px 45px;
  }

  .redirection_card_section {
    padding: 0 30px 60px 30px;
  }

  .main_content_details .heading_section .main_heading_desktop {
    font-size: 20px;
  }

  .main_content_details .both_section .content_section p {
    font-size: 15px;
  }

  .search_cat_section_heading {
    font-size: 20px;
  }

  .search_cat_section_content {
    font-size: 15px;
  }

  .redirection_card_section .main_heading {
    font-size: 20px;
  }

  .nav_link_cards .project_card .card_box .card_content .card_heading {
    font-size: 18px;
  }

  .nav_link_cards .project_card .card_box .card_content {
    padding: 20px;
  }

  .search_cat_btn {
    font-size: 20px;
    padding: 5px 0;
  }

  .combined_content {
    min-height: 190px;
  }
}

@media (max-width: 768px) {
  .breadcrumb_section div {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .breadcrumb_section {
    display: none;
  }
}

@media (max-width: 525px) {
  .overview_section {
    display: none;
  }

  .main_content_details .heading_section {
    display: none;
  }

  .accessibility_section .heading_section {
    display: block;
  }

  .visit_section .heading_section {
    display: block;
  }

  .breadcrumb_section {
    display: none;
  }

  .library_section_desktop {
    padding: 15px;
  }

  .accessibility_footer_section p {
    font-size: 15px;
    padding-bottom: 0 !important;
  }

  .accessibility_footer_section {
    padding: 15px;
  }

  .lib-content-col {
    padding: 0px !important;
  }

  .lib-image-col-desktop {
    display: none;
  }

  .lib-image-col-mobile {
    display: block;
    padding: 0px !important;
    margin-bottom: 20px;
  }

  .library_row {
    display: flex;
    flex-direction: column-reverse;
  }

  .mobile_heading {
    margin: 0 0 15px 0;
    font-family: kohinoorsemibold;
    font-size: 20px;
  }

  .mobile_sub_heading {
    margin: 0;
    font-family: kohinoorsemibold;
    font-size: 15px;
  }

  .mobile_content {
    margin: 0;
    font-family: kohinoorregular;
    font-size: 15px;
    color: #6e6e6e;
  }

  .search_card_section {
    padding: 15px;
  }

  .search_cat_section {
    padding: 0 10px 0 0 !important;
  }

  .book_section {
    padding: 0px 0px 0px 10px !important;
  }

  .combined_content {
    padding: 0;
    background-color: transparent;
    margin-bottom: 15px;
    min-height: auto;
  }

  .search_cat_section_heading {
    font-size: 18px;
    min-height: 59px;
  }

  .search_cat_section_content {
    font-size: 14px;
  }

  .search_cat_btn {
    font-size: 14px;
    padding: 9px 0;
  }

  .for_another_page {
    visibility: hidden !important;
  }

  .redirection_card_section {
    /* display: none; */
    padding: 0 15px 30px 15px;
  }

  .nav_link_cards .project_card {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }

  .nav_link_cards .project_card:last-child {
    margin-bottom: 0;
  }

  .redirection_card_section .main_heading {
    padding: 20px 0 30px 0;
  }

  .infoSection {
    display: none;
    background: #f4f4f4;
    padding: 15px;
  }

  .info_list {
    font-size: 15px;
    font-family: kohinoorregular;
    color: #1e1e1e;
  }

  .list_heading {
    margin-bottom: 0;
  }

  .anchor_link {
    color: rgb(0, 0, 255) !important;
    text-decoration: underline !important;
    font-size: 15px;
    font-weight: 500;
    font-family: kohinoorregular;
  }

  .footer_mobile {
    display: block;
    background: #f4f4f4;
    padding: 30px 15px 60px 15px;
  }

  .breadcrumb_section {
    padding: 16.5px 30px;
    background: #ffffff;
  }

  .breadcrumb_section div {
    font-size: 18px;
    font-family: "Kohinoorregular";
  }

  .breadcrumb_section div span {
    font-size: 25px;
  }

  .visitor_card_heading {
    font-size: 18px;
    height: auto;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 375px) {
  .footerImage {
    width: 90%;
  }

  .footerIcons {
    /* width: 20%; */
    margin: 0 4px;
  }
}

.detailSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #f4f4f4;
}

.detailHeading {
  line-height: 30px;
  letter-spacing: 3px;
  font-size: 24px;
  font-weight: unset;
  color: #232323;
}

.detailList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.detailCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.detailCardUpper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.question {
  font-size: 25px;
  width: 95%;
  color: #000000;
  font-family: "kohinoorlight", sans-serif;
  font-weight: unset;
}

.line {
  width: 100%;
  margin: 10px 0px;
  color: #cacaca;
  opacity: 0.5;
}

.answer {
  font-size: 20px;

  margin: 2%;

  color: #6e6e6e;
  line-height: 2rem;
  animation: slideDown 1s ease;
  font-family: "kohinoorlight", sans-serif;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  width: 100%;
  gap: 2%;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.answerRight {
  width: 50%;
}

.arrowDown {
  font-size: 25px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  color: #6e6e6e;
  box-shadow: 4px 5px 5px 3px rgba(151, 152, 253, 0.2);
  -webkit-box-shadow: 4px 5px 5px 3px rgba(151, 152, 253, 0.2);
  -moz-box-shadow: 4px 5px 5px 3px rgba(151, 152, 253, 0.2);
  border-radius: 50%;

  cursor: pointer;
}

@media (max-width: 1025px) {
  .arrowDown {
    font-size: 1.5rem;
  }

  .question {
    font-size: 18px;
  }

  .answer {
    font-size: 15px;
  }
}

@media (max-width: 850px) {
  .detailHeading {
    font-size: 2rem;
  }

  .arrowDown {
    font-size: 1.5rem;
  }

  .answer {
    flex-direction: column;
  }

  .answerLeft {
    width: 100% !important;
  }

  .answerRight {
    width: 100% !important;
  }
}

@media (max-width: 541px) {
  .question {
    width: 95%;
  }
}